import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-sky-400 text-white py-3 -mb-6">
      <div className="container mx-auto font-MontBold text-xl  px-4">
        <p className="text-center">&copy; 2024 TREAZ All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;